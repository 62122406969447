<template>
    <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
        <!---栅格布局 布局开始-->
        <a-row class="benben-grid  heartcheckManagementpicChecklist_flex_0">


            <a-col :span="24">
                <a-form-model ref="formRef1686814800557" class="benben-flex-form heartcheckManagementpicChecklist_fd0_0_c0" label-align="right" layout="horizontal" :label-col="{span:8,offset:0}" :wrapper-col="{span:16,offset:0}">
                    <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd0_0_c0_c0" layout="4:4:4:4:4:4" :gutter="[8,8]">

                        <a-col :span="4" class="flex-sub">
                            <a-form-model-item need="false" :rules="[]" :colon="true" label="">
                                <a-input class="heartcheckManagementpicChecklist_fd0_0_c0_c0_c0_c0" :max-length="-1" placeholder="审核类型" :allow-clear="true">
                                </a-input>
                            </a-form-model-item>

                        </a-col>



                        <a-col :span="4" class="flex-sub">
                            <a-form-model-item need="false" :rules="[]" :colon="true" label="">
                                <simple-select :disabled="false" mode="default" :options="fieldName_7495" :field-names='{"label":"name","value":"aid","children":"child"}'>

                                </simple-select>
                            </a-form-model-item>

                        </a-col>



                        <a-col :span="4" class="flex-sub">
                            <a-form-model-item need="false" :rules="[]" :colon="true" label="">
                                <a-input class="heartcheckManagementpicChecklist_fd0_0_c0_c0_c2_c0" :max-length="-1" placeholder="请输入" :allow-clear="true">
                                </a-input>
                            </a-form-model-item>

                        </a-col>



                        <a-col :span="4" class="flex-sub">
                            <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                                <simple-select :disabled="false" mode="default" :options="fieldName_3051" :field-names='{"label":"name","value":"aid","children":"child"}'>

                                </simple-select>
                            </a-form-model-item>

                        </a-col>



                        <a-col :span="4" class="flex-sub">
                            <a-input class='benben-flex-input-diy' placeholder="请输入" :max-length="240" size="default" :allow-clear='true'>
                            </a-input>


                        </a-col>



                        <a-col :span="4" class="flex-sub">
                            <a-input class='benben-flex-input-diy' placeholder="请输入" :max-length="240" size="default" :allow-clear='true'>
                            </a-input>


                        </a-col>

                    </a-row>
                    <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd0_0_c0_c1" layout="4:4:4:4:4:4" :gutter="[8,8]">

                        <a-col :span="4" class="flex-sub">

                        </a-col>



                        <a-col :span="4" class="flex-sub">

                        </a-col>



                        <a-col :span="4" class="flex-sub">

                        </a-col>



                        <a-col :span="4" class="flex-sub">

                        </a-col>



                        <a-col :span="4" class="flex-sub">

                        </a-col>



                        <a-col :span="4" class="flex-sub">
                            <a-button class="heartcheckManagementpicChecklist_fd0_0_c0_c1_c5_c0">查询</a-button>

                            <a-button>重置</a-button>


                        </a-col>

                    </a-row>
                </a-form-model>

            </a-col>



            <a-col :span="18">
                <a-button class="heartcheckManagementpicChecklist_fd0_1_c0" :disabled="false">批量审核成功</a-button>

                <a-button class="heartcheckManagementpicChecklist_fd0_1_c1" :disabled="false">批量审核失败</a-button>


            </a-col>



            <a-col :span="6">

            </a-col>


        </a-row>
        <!---栅格布局 布局结束-->

        <a-modal id="antdVue" class=" width_fullscreen " :centered="false" :closable="true" :mask="true" :mask-closable="true" title="标题" width="50%" v-model='isShow'>
            <div class="flex  flex-wrap align-center justify-end  heartcheckManagementpicChecklist_modal1_0" slot="footer">
                <a-button class="heartcheckManagementpicChecklist_modal1_0_c0" :disabled="false">提交</a-button>

                <a-button class="heartcheckManagementpicChecklist_modal1_0_c1" :disabled="false">取消</a-button>


            </div>

            <img class='heartcheckManagementpicChecklist_modal1_1' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

        </a-modal>

        <!---栅格布局 布局开始-->
        <a-row class="benben-grid  heartcheckManagementpicChecklist_flex_2">


            <a-col :span="24">
                <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd2_0_c0" layout="24:18:6" :gutter="[8,8]">

                    <a-col :span="24">
                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0" isAlone="false">
                            <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0" layout="24:24:24" :gutter="[8,8]">

                                <a-col :span="24" class="flex-sub heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0">
                                    <div class="flex align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                        <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                            <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                                    <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                                            <img class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0" src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" @click="previewImg()" />

                                                            <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c1" :disabled="false" size="small" @click="toDetail(1)">审核成功</a-button>

                                                            <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c2" :disabled="false" size="small">审核失败</a-button>


                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c1" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c1_c0">状态：</span>

                                                            <span>等待审核</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c2" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c2_c0">账号：</span>

                                                            <span>tangtang8956</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c3" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c3_c0">昵称：</span>

                                                            <span>GA13589</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c4" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c4_c0">状态：</span>

                                                            <span>等待审核</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c5" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c5_c0">创建时间：</span>

                                                            <span>等待审核</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c6" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c6_c0">处理时间：</span>

                                                            <span>等待审核</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c7" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c7_c0">类型：</span>

                                                            <span>等待审核</span>

                                                        </div>

                                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c8" isAlone="false">

                                                            <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c8_c0">处理人：</span>

                                                            <span>等待审核</span>

                                                        </div>


                                                    </div>


                                                </div>


                                            </div>


                                        </div>

                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1" isAlone="false">
                                            <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0" isAlone="false">
                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0" isAlone="false">
                                                    <img class='heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                                    <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0_c1" :disabled="false" size="small">审核成功</a-button>

                                                    <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0_c2" :disabled="false" size="small">审核失败</a-button>


                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c1" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c1_c0">状态：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c2" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c2_c0">账号：</span>

                                                    <span>tangtang8956</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c3" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c3_c0">昵称：</span>

                                                    <span>GA13589</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c4" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c4_c0">状态：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c5" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c5_c0">创建时间：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c6" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c6_c0">处理时间：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c7" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c7_c0">类型：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c8" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c8_c0">处理人：</span>

                                                    <span>等待审核</span>

                                                </div>


                                            </div>


                                        </div>

                                        <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2" isAlone="false">
                                            <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0" isAlone="false">
                                                <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0" isAlone="false">
                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0" isAlone="false">
                                                        <img class='heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                                        <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0_c1" :disabled="false" size="small">审核成功</a-button>

                                                        <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0_c2" :disabled="false" size="small">审核失败</a-button>


                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c1" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c1_c0">状态：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c2" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c2_c0">账号：</span>

                                                        <span>tangtang8956</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c3" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c3_c0">昵称：</span>

                                                        <span>GA13589</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c4" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c4_c0">状态：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c5" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c5_c0">创建时间：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c6" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c6_c0">处理时间：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c7" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c7_c0">类型：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c8" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c8_c0">处理人：</span>

                                                        <span>等待审核</span>

                                                    </div>


                                                </div>


                                            </div>


                                        </div>

                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3" isAlone="false">
                                            <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0" isAlone="false">
                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0" isAlone="false">
                                                    <img class='heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                                    <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0_c1" :disabled="false" size="small">审核成功</a-button>

                                                    <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0_c2" :disabled="false" size="small">审核失败</a-button>


                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c1" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c1_c0">状态：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c2" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c2_c0">账号：</span>

                                                    <span>tangtang8956</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c3" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c3_c0">昵称：</span>

                                                    <span>GA13589</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c4" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c4_c0">状态：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c5" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c5_c0">创建时间：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c6" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c6_c0">处理时间：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c7" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c7_c0">类型：</span>

                                                    <span>等待审核</span>

                                                </div>

                                                <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c8" isAlone="false">

                                                    <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c8_c0">处理人：</span>

                                                    <span>等待审核</span>

                                                </div>


                                            </div>


                                        </div>

                                        <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4" isAlone="false">
                                            <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0" isAlone="false">
                                                <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0" isAlone="false">
                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0" isAlone="false">
                                                        <img class='heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                                        <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0_c1" :disabled="false" size="small">审核成功</a-button>

                                                        <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0_c2" :disabled="false" size="small">审核失败</a-button>


                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c1" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c1_c0">状态：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c2" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c2_c0">账号：</span>

                                                        <span>tangtang8956</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c3" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c3_c0">昵称：</span>

                                                        <span>GA13589</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c4" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c4_c0">状态：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c5" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c5_c0">创建时间：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c6" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c6_c0">处理时间：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c7" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c7_c0">类型：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c8" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c8_c0">处理人：</span>

                                                        <span>等待审核</span>

                                                    </div>


                                                </div>


                                            </div>


                                        </div>

                                        <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5" isAlone="false">
                                            <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0" isAlone="false">
                                                <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0" isAlone="false">
                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0" isAlone="false">
                                                        <img class='heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                                        <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0_c1" :disabled="false" size="small">审核成功</a-button>

                                                        <a-button class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0_c2" :disabled="false" size="small">审核失败</a-button>


                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c1" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c1_c0">状态：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c2" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c2_c0">账号：</span>

                                                        <span>tangtang8956</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c3" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c3_c0">昵称：</span>

                                                        <span>GA13589</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c4" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c4_c0">状态：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c5" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c5_c0">创建时间：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c6" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c6_c0">处理时间：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c7" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c7_c0">类型：</span>

                                                        <span>等待审核</span>

                                                    </div>

                                                    <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c8" isAlone="false">

                                                        <span class="heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c8_c0">处理人：</span>

                                                        <span>等待审核</span>

                                                    </div>


                                                </div>


                                            </div>


                                        </div>


                                    </div>


                                </a-col>



                                <a-col :span="24">

                                </a-col>



                                <a-col :span="24">

                                </a-col>

                            </a-row>

                        </div>


                    </a-col>



                    <a-col :span="18">

                    </a-col>



                    <a-col :span="6">

                    </a-col>

                </a-row>

            </a-col>



            <a-col :span="18">

            </a-col>



            <a-col :span="6">

            </a-col>


        </a-row>
        <!---栅格布局 布局结束-->



    </div>
</template>

<script>
    import {
        receive,
        benbenSelectDiy,
        benbenFlexNumberBox,
        scrollList,
        treeSelect,
        simpleSelect,
        multipleSelect,
        cascaderForm,
        checkboxForm,
        switchForm,
        messageReply,
        rangePicker,
        handSignature,
        mixinAddTableItem,
        editorForm,
        qiunDataCharts,
        copyText,
        mixinTableItemChange,
        in_array,
        themeColorInit,
        getUrlKey,
        clearDataPass,
        isEmpty
    } from '@/assets/diypaaspc/diy-pass-pc.js'
    import {
        provinceForm
    } from '@/assets/diypaaspc/province_area.js'
    import request from '@/utils/request'
    export default {
        name: "goods",
        components: {
            qiunDataCharts,
            benbenSelectDiy,
            //            productSelect, 
            benbenFlexNumberBox,
            scrollList,
            treeSelect,
            simpleSelect,
            messageReply,
            rangePicker,
            multipleSelect,
            provinceForm,
            editorForm,
            cascaderForm,
            switchForm,
            checkboxForm,
            //            uploadImage,
            //            uploadFile,
            //            uploadMedia,
            //            uploadSingleFile,
            handSignature
        },
        data() {
            receive(this);
            const themeColorConfig = {
                "presetApi": {
                    "getClassifyOne": "",
                    "getClassifyTow": "",
                    "getClassifyShop": ""
                },
                "themeType": 0,
                "themeConfig": [{
                    "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
                    "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
                    "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
                }]
            };
            return {
                themeColorConfig,
                "fieldName_3051": [{
                    "aid": "1",
                    "name": "选项1"
                }, {
                    "aid": "2",
                    "name": "选项2"
                }],
                "fieldName_7495": [{
                    "aid": "1",
                    "name": "选项1"
                }, {
                    "aid": "2",
                    "name": "选项2"
                }],
                "isShow": false
            };

        },
        computed: {

        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {

        },
        watch: {},
        methods: {
            copyText,
            mixinTableItemChange,
            in_array,
            themeColorInit,
            isEmpty,
            //预览图片
            previewImg() {
                this.isShow = true;
            },
            //跳转文章详情
            toDetail(id) {
                this.$router.push({
                    path: '/heart/checkManagement/textDetail',
                    query: {
                        id: id
                    }
                });

            }
        }
    }
</script>

<style lang="less" scoped>
    @import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

    .antdVue {
        width: 100%;
        background: #F8F8F8;
        background-size: 100% auto !important;
    }

    #antdVue .heartcheckManagementpicChecklist_flex_0 {
        background: #fff;
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_1_c1 {
        background: var(--benbenbgColor3);
        margin: 0px 0px 0px 10px;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_1_c0 {
        background: var(--benbenbgColor2);
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c1_c5_c0 {
        background: #4781f5;
        margin: 0px 10px 0px 0px;
        background-size: 100% auto !important;
        color: #fff;
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c1 {
        background: #fff;
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c0_c2_c0 {
        font-size: 14px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c0_c0_c0 {
        font-size: 14px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c0 {
        background: #fff;
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd0_0_c0 {
        background: #fff;
        width: 100%;
        min-height: 100px;
    }

    #antdVue .heartcheckManagementpicChecklist_modal1_1 {
        width: 200px;
        height: 200px;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_modal1_0_c1 {
        border-radius: 25px 25px 25px 25px;
        font-size: 14px;
        color: rgba(0, 0, 0, .65);
    }

    #antdVue .heartcheckManagementpicChecklist_modal1_0_c0 {
        background: #4781f5;
        border-radius: 25px 25px 25px 25px;
        font-size: 14px;
        color: #fff;
        margin: 0px 15px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_modal1_0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_flex_2 {
        background: #fff;
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c8_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c8 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c7_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c7 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c6_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c6 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c5_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c4_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c4 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c3_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c2_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c2 {
        width: 100%;
        overflow-x: auto;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c1_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0_c2 {
        background: rgba(242, 62, 15, 1);
        position: absolute;
        right: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0_c1 {
        background: #50AE55;
        position: absolute;
        left: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0_c0 {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0_c0 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5_c0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c8_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c8 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c7_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c7 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c6_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c6 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c5_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c4_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c4 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c3_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c2_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c2 {
        width: 100%;
        overflow-x: auto;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c1_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0_c2 {
        background: rgba(242, 62, 15, 1);
        position: absolute;
        right: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0_c1 {
        background: #50AE55;
        position: absolute;
        left: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0_c0 {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0_c0 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4_c0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c4 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c8_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c8 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c7_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c7 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c6_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c6 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c5_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c4_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c4 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c3_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c2_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c2 {
        width: 100%;
        overflow-x: auto;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c1_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0_c2 {
        background: rgba(242, 62, 15, 1);
        position: absolute;
        right: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0_c1 {
        background: #50AE55;
        position: absolute;
        left: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0_c0 {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3_c0 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c8_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c8 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c7_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c7 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c6_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c6 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c5_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c4_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c4 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c3_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c2_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c2 {
        width: 100%;
        overflow-x: auto;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c1_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0_c2 {
        background: rgba(242, 62, 15, 1);
        position: absolute;
        right: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0_c1 {
        background: #50AE55;
        position: absolute;
        left: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0_c0 {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0_c0 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2_c0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c2 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c8_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c8 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c7_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c7 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c6_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c6 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c5_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c4_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c4 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c3_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c2_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c2 {
        width: 100%;
        overflow-x: auto;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c1_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0_c2 {
        background: rgba(242, 62, 15, 1);
        position: absolute;
        right: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0_c1 {
        background: #50AE55;
        position: absolute;
        left: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0_c0 {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1_c0 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c8_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c8 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c7_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c7 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c6_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c6 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c5_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c5 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c4_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c4 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c3_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c3 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c2_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c2 {
        width: 100%;
        overflow-x: auto;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c1_c0 {
        width: 80px;
        text-align: right;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c1 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c2 {
        background: rgba(242, 62, 15, 1);
        position: absolute;
        right: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c1 {
        background: #50AE55;
        position: absolute;
        left: 10%;
        background-size: 100% auto !important;
        color: var(--benbenFontColor3);
        bottom: 10%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0 {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0_c0 {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0_c0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0_c0 {
        width: 100%;
        position: relative;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0_c0 {
        padding: 20px;
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0_c0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0_c0 {
        margin: 10px 0px 10px 0px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0_c0 {
        width: 100%;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0_c0_c0 {
        background: rgba(245, 250, 254, 1);
        width: 100%;
        background-size: 100% auto !important;
        padding: 15px 0px 25px 0px;
        border-radius: 8px 8px 8px 8px;
    }

    #antdVue .heartcheckManagementpicChecklist_fd2_0_c0 {
        background: #fff;
        width: 100%;
    }
</style>